.subItem {
  margin-bottom: 0px;
  font-size: 13px!important;
  font-weight: 600!important;
  margin-top: 5px;
}

.toolBarTop {
  display: flex;
  flex-direction: row;
  /* width: 147vh; */
  text-align: start;
  opacity: 0.8;
}

.toolBar {
  height: 100vh;
  opacity: 0.8;
}

.fieldset {
  border: 3px solid #accbd4;
  border-radius: 15px;
  font-size: 24px;
  font-weight: bold;
  padding-inline: 20px;
  padding-bottom: 10px;
  width: 740px;
}

.legend {
  width: fit-content !important ;
  border: 3px solid #accbd4 !important;
  margin-bottom: 0px !important;
  padding-inline: 10px !important;
  border-radius: 15px;
  color: #003c4c !important;
  font-weight: bold;
  font-size: 30px !important;
  font-style: italic;
}
